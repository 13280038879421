<template>
  <div class="activity_home">
    <div class="video">
      <video id="media" controls muted autoplay="autoplay" style="width:100%;">
        <source :src="hd.video_path"/>
      </video>
    </div>
    <div class="container">
      <div class="activity_time">{{hd.time_info}}</div>
      <!--<div class="column">
        <div class="h4">活动倒计时</div>
        <div class="h5">COUNT DOWN</div>
      </div>
      <div class="countDown">
        <van-count-down :time="time">
          <template #default="timeData">
            <span v-if="hd.state==2">活动已结束</span>
            <template v-else>
              <span>距{{hd.state==0?'开始':'结束'}}：</span>
              <span class="block">{{ timeData.days }}</span>
              <span class="colon">天</span>
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">时</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">分</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">秒</span>
            </template>
          </template>
        </van-count-down>
      </div>-->
      
      <van-row class="user_statistics">
        <van-col span="8" class="item">
          <label>{{hd.total_user}}</label>
          <span>已转发</span>
        </van-col>
        <van-col span="8" class="item">
          <label>{{hd.tj_num}}</label>
          <span>已兑换</span>
        </van-col>
        <van-col span="8" class="item" v-if="myhdinfo.statistics">
          <label>{{myhdinfo.statistics.digg_count}}</label>
          <span>我的点赞</span>
        </van-col>
      </van-row>
      <div class="hd_title">{{hd.title}}</div>
      <div class="synopsis">{{hd.synopsis}}</div>
      <div class="mt10" v-if="goodList.length"><goodList :order_type="2" :dataList="goodList" :data="{...hd,like_count_surplus:myhdinfo.statistics.digg_count-myhdinfo.mycount}"/></div>
      <div class="mt20"><shopInfo :data="{...hd.shopInfo,userId:hd.userId}" :source="2"/></div>
      <div class="textContent index_box" v-html="hd.content" v-if="hd.content != null && hd.content != ''"></div>
    </div>
  </div>
</template>

<script>
  import shopInfo from '@/pages/douyin/components/shopInfo'
  import goodList from '@/pages/douyin/components/goodList'
export default {
  props: {
    hd:Object,
    myhdinfo:Object,
    goodList:Array
  },
  components: {goodList,shopInfo},
  data() {
    return {
      time:0
    }
  },
  activated() {
    this.setBgColor()
  },
  created(){
    this.setBgColor()
  },
  mounted() {
    this.time = new Date(this.hd.end_time).getTime() - new Date().getTime();
  },
  methods: {
    setBgColor(){
      if(this.hd.bgcolor==null || this.hd.bgcolor==''){
        document.querySelector('body').setAttribute('style', 'background-color:#555')
      }
    }
  }
}
</script>
<style lang="less">
  .activity_home{
    .container{padding:10px;}
  
    .column{height:auto;text-align:center;padding:10px 0px;
      .h4{color:#9c1617;font-size:18px;font-weight:700;}
      .h5{color:#9c1617;margin-top:10px;}
    }
    .countDown{background:#fff;border-radius:4px;padding:15px 0px;text-align:center;
      .block{background:#dd5563;}
    }
    .user_statistics{background: rgba(0,0,0,0.6);border-radius:4px;padding:15px 0px;height:auto;margin:10px 10px 0px;border: 1px solid #ff6a00;
      .item{text-align:center;color:#ff6a00;
        label{font-size:20px;width:100%;display:inline-block;font-weight:700;}
        span{width:100%;display:inline-block;margin-top:5px;}
      }
    }
    .hd_title{color:#fff;font-size:18px;margin-top:20px;font-weight:700;line-height:24px;}
    .synopsis{color:#fff;margin-top:10px;line-height:24px;}
    .goodList{
      .item{
        .action{
          .van-button--danger{background:#dd5563;border:1px solid #dd5563;}
        }
      }
    }
    .index_box{border-radius:4px;background:#fff;padding:10px;margin-top:10px;}
    
  }
  .footer{color:#fff;}
  .footerAction{
    .btn1{background:#ff4d4f;border:1px solid #ff4d4f;}
    .btn2{background:#ff6a00;border:1px solid #ff6a00;}
  }
</style>
