<template>
  
  <div class="goodList" >
    <div class="middle">
      <div v-for="item in goodList" :key="item.id" :class="['item',item.stock<=0?'sell_out':'']">
        <img :src="require('@/assets/image/icon_sell_'+item.label+'.png')" v-if="item.label>0" class="iconSell"/>
        <van-row type="flex" :gutter="10" class="ginfo">
          <van-col span="6" class="img"><van-image width="100%" lazy-load :src="item.titleimg"/></van-col>
          <van-col span="18" class="info">
            <div class="title">
              <span class="package" v-if="item.package_key>0">[{{item.package_name}}]</span>
              <span class="t">{{item.title}}</span>
            </div>
            <div class="price">
              <span class="now_price"><van-icon class-prefix="ico" name="rmb" /><em>{{item.now_price}}</em></span>
              <s><van-icon class-prefix="ico" name="rmb" />{{item.old_price}}</s>
            </div>
            <van-row type="flex" justify="space-between" class="mt5">
              <van-col class="font-color-yellow">已有 <van-icon class-prefix="ico" name="smile" /> {{item.tj_num}} 人兑换</van-col>
              <van-col class="font-color-gray">仅剩{{item.stock}}{{item.dw}}</van-col>
            </van-row>
          </van-col>
        </van-row>
        <div class="action">
          <van-row type="flex" justify="center" :gutter="5">
            <van-col :span="12"><van-button type="info" size="small" block round @click="onDetail(item)">查看详情</van-button></van-col>
            <van-col :span="12">
              <van-button type="danger" size="small" block round @click="exchange(item)" v-if="data.state==1" :color="(data.like_count_surplus>=item.like_count && item.stock>0)?'':'#999'">立即兑换</van-button>
              <van-button type="danger" size="small" block round :color="data.state==0?'#07c160':'#888'" v-else>{{data.state==0?'即将开始':'活动已结束'}}</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    
    <van-popup v-model="detailModal" position="bottom" closeable :style="{ height: '100%' }">
      <div class="goodDetail">
        <div class="title">{{currentInfo.title}}</div>
        <van-row type="flex" justify="space-between" align="bottom" class="other">
          <van-col class="font-color-yellow">{{currentInfo.remarks}}</van-col>
          <van-col>库存{{currentInfo.stock}}{{currentInfo.dw}}</van-col>
        </van-row>
        <div class="textContent mt10" v-html="currentInfo.content"></div>
      </div>
    </van-popup>
  
    <van-popup v-model="linquModal" position="bottom" class="ExchangePopup">
      <van-form @submit="onSubmitExchange">
        <van-cell-group class="contact">
          <van-field v-model="json.name" label="姓名" placeholder="请输入姓名" input-align="right" :rules="[{ required: true,message: '请输入姓名'}]" :error="false" error-message-align="right"/>
          <van-field v-model="json.mobile" label="手机" placeholder="请输入联系方式" input-align="right" :rules="[{ required: true,validator:validatorPhone,message: '请输入正确的手机号码'}]" :error="false" error-message-align="right"/>
        </van-cell-group>
        <div class="font-color-red mt10" v-if="json.stock>0">提交后在领取期内到店领(否则视为放弃)</div>
        <div class="font-color-gray mt10" v-else>库存不足，立即联系商家补货</div>
        <div class="mt10"><van-button color="linear-gradient(to right, #ff6034, #ee0a24)" size="small" block round native-type="submit">立即兑换</van-button></div>
      </van-form>
    </van-popup>
  </div>

</template>
<script>
  import {GoodExchange} from "../services/services";
export default {
  props: {
    dataList:Array,
    data:Object
  },
  components: {},
  data() {
    return {
      goodList:[],
      show_type:2,
      detailModal:false,
      currentInfo:{},
      json:{
        name:'',
        mobile:''
      },
      linquModal:false
    }
  },
  created(){
  },
  mounted() {
    this.goodList = this.dataList
  },
  methods: {
    validatorPhone(val) {
      return /^1[3456789]\d{9}$/.test(val);
    },
    onDetail(record){
      this.currentInfo = record
      this.detailModal=true
    },
    exchange(record){
      if(record.like_count>this.data.like_count_surplus){
        this.$toast('点赞数需达到'+record.like_count+'才可以兑换哦，加油吧！');
        return false
      }
      if(record.stock<=0){
        this.$toast('库存不足，立即联系商家补货');
        return false
      }
      this.json={
        ...this.json,
        ...record
      }
      this.linquModal = true
    },
    onSubmitExchange(){
      this.$toast.loading({message: '正在兑换中...',forbidClick: true})
      const {name,mobile,id,hdid,like_count} = this.json
      let json={name,mobile,pid:id,hdid,like_count}
      GoodExchange(json).then(result => {
        if(result.data.code==1){
          this.linquModal=false
          this.data.like_count_surplus--
          this.$toast.success(result.data.msg)
        }else{
          this.$toast(result.data.msg)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  @import "@/assets/css/good.less";
</style>
